import React, { useState } from 'react';

import SimpleBarReact from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Toolbar from '@mui/material/Toolbar';

import AccessIcon from '@mui/icons-material/AssignmentInd';
import ClearIcon from '@mui/icons-material/Clear';
import ComponentsIcon from '@mui/icons-material/AccountTree';
import FleetIcon from '@mui/icons-material/LocalShipping';
import MenuIcon from '@mui/icons-material/Menu';
import ReportIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';

import AppRoutes from 'src/routes/appRoutes';

import AppIcon from 'src/assets/img/brand/maintenance/cargofix-icon.svg';
import AppLogo from 'src/assets/img/brand/maintenance/cargofix-logo-sticker.png';

import { getCachedPermissions, getCachedSuperUser, removePermissionsData } from 'src/services/storage/permissions';
import { removeCompaniesVisualData } from 'src/services/storage/company';
import { removeUserData } from 'src/services/storage/user';

import CollapsableNavItem from './CollapsableNavItem';
import Item, { NavItemProps } from './NavItem';

const SideNavWrapper = styled(Box)<{ show: number }>(({ theme, show }) => ({
    [theme.breakpoints.up('xs')]: {
        width: '100%',
        display: show ? 'block' : 'none',
    },
    [theme.breakpoints.up('md')]: {
        width: '260px',
        display: 'block',
    },
    background: theme.palette.primary.main,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    maxHeight: '100%',
}));

const SidebarDivider = styled(Divider)(({ theme }) => ({
    marginTop: '10px',
    marginBottom: '10px',
    borderColor: theme.palette.secondary.main,
    borderWidth: 1.2,
    opacity: 0.5,
}));

const Sidebar = () => {
    const [show, setShow] = useState(false);

    const onCollapse = () => setShow(!show);

    /**
     * PERMISSIONS
     */
    const permissions = getCachedPermissions();
    const superuser = getCachedSuperUser();

    const NavItem = (props: Omit<NavItemProps, 'setShow' | 'permissions' | 'superuser'>) => {
        return <Item {...props} setShow={setShow} permissions={permissions} superuser={superuser} />;
    };

    const reloadLocalStorage = () => {
        removeCompaniesVisualData();
        removePermissionsData();
        removeUserData();
        window.location.reload();
    };

    return (
        <>
            <AppBar
                position={'static'}
                sx={{
                    px: 4,
                    display: {
                        xs: 'block',
                        md: 'none',
                    },
                }}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box component={'img'} alt={'Icone'} src={AppIcon} width={25} />
                    <IconButton
                        size={'large'}
                        edge={'start'}
                        onClick={onCollapse}
                        sx={{
                            color: 'white',
                        }}
                        children={<MenuIcon />}
                    />
                </Toolbar>
            </AppBar>

            <SideNavWrapper show={show ? 1 : 0}>
                <SimpleBarReact style={{ maxHeight: '100%' }}>
                    <Box px={2} py={3}>
                        <Stack
                            direction={'row-reverse'}
                            display={{
                                md: 'none',
                            }}
                            children={
                                <IconButton
                                    size={'large'}
                                    edge={'start'}
                                    onClick={onCollapse}
                                    children={<ClearIcon />}
                                />
                            }
                        />

                        <Stack>
                            <Box
                                py={1.8}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                gap={1.2}
                                children={
                                    <Box
                                        component={'img'}
                                        alt={'Logomarca'}
                                        src={AppLogo}
                                        onClick={reloadLocalStorage}
                                        maxWidth={'95%'}
                                    />
                                }
                            />

                            <SidebarDivider />

                            <CollapsableNavItem title={'Frota'} icon={FleetIcon} permissions={permissions}>
                                <NavItem title={'Alocações'} route={AppRoutes.Allocations} />
                                <CollapsableNavItem title={'Cadastros'} permissions={permissions}>
                                    <NavItem title={'Veículos'} route={AppRoutes.Vehicles} />
                                    <NavItem title={'Implementos'} route={AppRoutes.Implements} />
                                    <NavItem title={'Equipamentos'} route={AppRoutes.Equipments} />
                                </CollapsableNavItem>
                            </CollapsableNavItem>

                            <CollapsableNavItem title={'Almoxarifado'} icon={ComponentsIcon} permissions={permissions}>
                                <NavItem title={'Requisições'} />
                                <CollapsableNavItem title={'Cadastros'} permissions={permissions}>
                                    <NavItem title={'Materiais'} route={AppRoutes.Products} />
                                    <NavItem title={'Grupo de Materiais'} route={AppRoutes.ProductGroup} />
                                    <NavItem title={'Subgrupo de Materiais'} route={AppRoutes.ProductSubgroup} />
                                    <NavItem title={'Marcas de Materiais'} route={AppRoutes.Brands} />
                                    <NavItem title={'Conjunto de Materiais'} route={AppRoutes.Bundles} />
                                </CollapsableNavItem>
                            </CollapsableNavItem>

                            <CollapsableNavItem title={'Manutenção'} icon={FleetIcon} permissions={permissions}>
                                <NavItem title={'Ordem de Serviço'} route={AppRoutes.Workorders} />
                                <CollapsableNavItem title={'Cadastros'} permissions={permissions}>
                                    <NavItem title={'Serviços'} route={AppRoutes.Services} />
                                    <NavItem title={'Grupos de Serviços'} route={AppRoutes.ServiceGroups} />
                                    <NavItem title={'Subgrupo de Serviços'} route={AppRoutes.ServiceSubgroups} />
                                    <NavItem title={'Conjunto de Serviços'} route={AppRoutes.Bundles} />
                                    <NavItem title={'Planos de Manutenção'} route={AppRoutes.Plans} />
                                </CollapsableNavItem>
                            </CollapsableNavItem>

                            <CollapsableNavItem title={'Configurações'} icon={SettingsIcon} permissions={permissions}>
                                <NavItem title={'Cores'} route={AppRoutes.Colors} />
                                <NavItem title={'Empresas'} route={AppRoutes.Enterprises} />
                                <NavItem title={'Marcas'} route={AppRoutes.Brands} />
                                <NavItem title={'Modelos'} route={AppRoutes.AssetModels} />
                                <NavItem title={'Pessoas'} route={AppRoutes.Persons} />
                                <NavItem title={'Unidades de Medida'} route={AppRoutes.MeasurementUnits} />
                                <NavItem title={'Tipo de Cabine'} route={AppRoutes.CabinTypes} />
                                <NavItem title={'Tipo de Rodado'} route={AppRoutes.AxleTypes} />
                                <NavItem title={'Tipo de Carroceria'} route={AppRoutes.TrailerTypes} />
                                <NavItem title={'Tipo de Veículo'} route={AppRoutes.VehicleTypes} />
                            </CollapsableNavItem>

                            <NavItem title={'Relatórios'} icon={ReportIcon} />

                            <SidebarDivider />

                            <CollapsableNavItem title={'Acessos'} icon={AccessIcon} permissions={permissions}>
                                <NavItem title={'Usuários'} route={AppRoutes.Users} />
                            </CollapsableNavItem>
                        </Stack>
                    </Box>
                </SimpleBarReact>
            </SideNavWrapper>
        </>
    );
};

export default Sidebar;
