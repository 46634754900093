import React from 'react';

import { Zoom } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ArrowIcon from '@mui/icons-material/ArrowForwardIosSharp';

import { CollapsableNavItemProps } from 'src/components/sidebar/CollapsableNavItem';

import { checkRoutePermission } from 'src/services/storage/permissions';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    minHeight: 40,
    maxHeight: 40,
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: theme.palette.secondary.main,
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-root': {
        paddingRight: 0,
        paddingLeft: 0,
    },
    '& .MuiAccordionSummary-content': {
        paddingRight: 0,
        maxWidth: '92%',
    },
}));

const CollapsableNavItem = (props: CollapsableNavItemProps) => {
    const { title, icon: Icon, imageSrc, children, permissions } = props;

    const hasChildren: (children: React.ReactElement[]) => boolean = (children) =>
        children.reduce((acc, child) => {
            if (child.type === CollapsableNavItem) {
                return hasChildren(child.props.children);
            }
            return acc || (child.props.route && checkRoutePermission(permissions, child.props.route, 'view'));
        }, false) as boolean;

    if (!hasChildren(Array.isArray(children) ? children : [children])) {
        return null;
    }

    return (
        <Tooltip title={title} placement={'right-start'} TransitionComponent={Zoom} arrow>
            <Accordion
                elevation={0}
                disableGutters
                sx={{
                    p: 0,
                    background: 'none',
                    '&:before': { display: 'none' },
                }}
            >
                <AccordionSummary
                    expandIcon={<ArrowIcon sx={{ fontSize: '0.9rem' }} />}
                    sx={{
                        m: 0,
                        px: 1,
                        borderRadius: 1,
                        '&:hover': {
                            backgroundColor: '#FFFFFF66',
                        },
                    }}
                    color={'secondary'}
                >
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'start'}
                        spacing={1}
                        maxWidth={'90%'}
                    >
                        {Icon && (
                            <Icon
                                sx={{
                                    fontSize: '14px',
                                    color: 'white',
                                }}
                            />
                        )}
                        {imageSrc && <img src={imageSrc} height={'20px'} />}
                        <Typography
                            color={'secondary'}
                            fontSize={'0.9rem'}
                            overflow={'hidden'}
                            textOverflow={'ellipsis'}
                            sx={{
                                textTransform: 'none',
                                whiteSpace: 'nowrap',
                            }}
                            children={title}
                        />
                    </Stack>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        pr: 0,
                        pl: 2,
                        bgcolor: '#ffffff12',
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                    }}
                    children={<Stack children={children} />}
                />
            </Accordion>
        </Tooltip>
    );
};

export default CollapsableNavItem;
