import AppRoutes from './appRoutes';

import AllocationsPage from 'src/pages/fleet/Allocation';
import AxleTypesPage from 'src/pages/fleet/AxleType';
import AssetGroupPage from 'src/pages/fleet/AssetGroup';
import AssetSubgroupsPage from 'src/pages/fleet/AssetSubgroup';
import BrandsPage from 'src/pages/registry/Brand/BrandsPage';
import BundlesPage from 'src/pages/catalog/Bundle';
import CabinTypesPage from 'src/pages/fleet/CabinType/CabinTypesPage';
import ColorsPage from 'src/pages/registry/Color/ColorsPage';
import EnterprisesPage from 'src/pages/registry/Enterprise';
import EquipmentsPage from 'src/pages/fleet/Equipment/EquipmentsPage';
import ImplementsPage from 'src/pages/fleet/Implement/ImplementsPage';
import MeasurementUnitsPage from 'src/pages/catalog/MeasurementUnits';
import ModelsPage from 'src/pages/fleet/AssetModel';
import PersonsPage from 'src/pages/registry/Person';
import PlansPage from 'src/pages/catalog/Plan';
import ProductGroupsPage from 'src/pages/catalog/products/Group';
import ProductSubgroupsPage from 'src/pages/catalog/products/Subgroup';
import ProductsPage from 'src/pages/catalog/products/Product';
import ServiceGroupsPage from 'src/pages/catalog/services/Group';
import ServiceSubgroupsPage from 'src/pages/catalog/services/Subgroup';
import ServicesPage from 'src/pages/catalog/services/Service';
import SettingsPage from 'src/pages/settings/SettingsPage';
import TrailerTypesPage from 'src/pages/fleet/TrailerType';
import UsersPage from 'src/pages/access/Users/Users';
import VehiclesPage from 'src/pages/fleet/Vehicle/VehiclesPage';
import VehicleTypesPage from 'src/pages/fleet/VehicleType/VehicleTypesPage';
import WorkordersPage from 'src/pages/maintenances/Workorder';

const PrivatePages = [
    // ADMIN
    {
        route: AppRoutes.Settings,
        component: SettingsPage,
    },

    // ACCESS
    {
        route: AppRoutes.Users,
        component: UsersPage,
    },

    // REGISTRY
    {
        route: AppRoutes.Colors,
        component: ColorsPage,
    },
    {
        route: AppRoutes.Brands,
        component: BrandsPage,
    },
    {
        route: AppRoutes.Enterprises,
        component: EnterprisesPage,
    },
    {
        route: AppRoutes.Persons,
        component: PersonsPage,
    },

    // CATALOG
    {
        route: AppRoutes.MeasurementUnits,
        component: MeasurementUnitsPage,
    },
    {
        route: AppRoutes.Bundles,
        component: BundlesPage,
    },

    // PRODUCTS
    {
        route: AppRoutes.Products,
        component: ProductsPage,
    },
    {
        route: AppRoutes.ProductGroup,
        component: ProductGroupsPage,
    },
    {
        route: AppRoutes.ProductSubgroup,
        component: ProductSubgroupsPage,
    },

    // SERVICES
    {
        route: AppRoutes.Services,
        component: ServicesPage,
    },
    {
        route: AppRoutes.ServiceGroups,
        component: ServiceGroupsPage,
    },
    {
        route: AppRoutes.ServiceSubgroups,
        component: ServiceSubgroupsPage,
    },
    {
        route: AppRoutes.Plans,
        component: PlansPage,
    },

    // FLEET
    {
        route: AppRoutes.CabinTypes,
        component: CabinTypesPage,
    },
    {
        route: AppRoutes.TrailerTypes,
        component: TrailerTypesPage,
    },
    {
        route: AppRoutes.AxleTypes,
        component: AxleTypesPage,
    },
    {
        route: AppRoutes.VehicleTypes,
        component: VehicleTypesPage,
    },
    {
        route: AppRoutes.AssetGroup,
        component: AssetGroupPage,
    },
    {
        route: AppRoutes.AssetSubgroup,
        component: AssetSubgroupsPage,
    },
    {
        route: AppRoutes.AssetModels,
        component: ModelsPage,
    },

    // ASSETS
    {
        route: AppRoutes.Vehicles,
        component: VehiclesPage,
    },
    {
        route: AppRoutes.Implements,
        component: ImplementsPage,
    },
    {
        route: AppRoutes.Equipments,
        component: EquipmentsPage,
    },
    {
        route: AppRoutes.Allocations,
        component: AllocationsPage,
    },

    // MAINTENANCES
    {
        route: AppRoutes.Workorders,
        component: WorkordersPage,
    },
];

export default PrivatePages;
