import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import api from '../../../services/api';
import CrudPassword from '../../../components/crud/fields/CrudPassword/CrudPassword';
import AppLogo from '../../../assets/img/brand/maintenance/cargofix-logo.png';

const ResetPasswordPage = () => {
    const navigate = useNavigate();

    /**
     * TOKEN VALIDATION
     */
    const [loading, setLoading] = useState(true);
    const [isValidToken, setIsValidToken] = useState(false);
    const token = window.location.pathname.split('/').pop();

    /**
     * VALUE CONTROL
     */
    const [values, setValues] = useState({
        id: 0,
        password: '',
        password_confirm: '',
    });
    const [error, setError] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitLoading(true);

        if (values.password !== values.password_confirm) {
            setError('As senhas não conferem.');
            return;
        }

        api.post('/api/v0/users/password-reset/confirm/', {
            token: token,
            password: values.password,
        })
            .then(() => {
                navigate('/');
            })
            .catch((err) => {
                setSubmitLoading(false);
                try {
                    setError(err.response.data.password[0]);
                } catch (e) {
                    setError('Erro ao tentar recuperar a senha.');
                }
            });
    };

    useEffect(() => {
        api.post('/api/v0/users/password-reset/validate-token/', {
            token: token,
        })
            .then(() => {
                setIsValidToken(true);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setError('Token de recuperação de senha inválido.');
            });
    }, [token]);

    if (loading) {
        return (
            <Stack
                alignItems={'center'}
                justifyContent={'center'}
                sx={{
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <main>
            <Stack
                alignItems={'center'}
                justifyContent={'center'}
                sx={{
                    height: '100vh',
                    px: 3,
                    py: 0,
                    my: 0,
                }}
            >
                <Stack
                    justifyContent={'center'}
                    sx={{
                        width: '100%',
                        maxWidth: '350px',
                    }}
                >
                    <Box
                        component={'img'}
                        src={AppLogo}
                        alt={'Logomarca'}
                        sx={{
                            mb: 3,
                            textAlign: 'center',
                        }}
                    />

                    {error && (
                        <Alert severity={'error'} sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}

                    {isValidToken && (
                        <form onSubmit={handleSubmit}>
                            <Stack
                                gap={2}
                                sx={{
                                    width: '100%',
                                }}
                            >
                                <CrudPassword
                                    column={{
                                        accessorKey: 'password',
                                        header: 'Senha',
                                    }}
                                    values={values}
                                    setValues={setValues}
                                />
                                <CrudPassword
                                    column={{
                                        accessorKey: 'password_confirm',
                                        header: 'Confirmação de Senha',
                                    }}
                                    values={values}
                                    setValues={setValues}
                                />

                                <Button
                                    variant={'contained'}
                                    type={'submit'}
                                    size={'large'}
                                    disabled={submitLoading}
                                    sx={{
                                        p: 1.5,
                                        mt: 1,
                                    }}
                                >
                                    Salvar
                                </Button>
                            </Stack>
                        </form>
                    )}
                </Stack>
            </Stack>
        </main>
    );
};

export default ResetPasswordPage;
