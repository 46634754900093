import React, { useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/PersonRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import api from 'src/services/api';
import AppRoutes from 'src/routes/appRoutes';

import { fetchUserData } from 'src/services/storage/user';
import { fetchCompanyVisualData } from 'src/services/storage/company';
import { setToken } from 'src/services/auth';

import AppLogo from 'src/assets/img/brand/maintenance/cargofix-logo.png';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLogged, setIsLogged] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const handleMouseDownPassword = () => {
        setShowPassword(true);
    };

    const handleMouseUpPassword = () => {
        setShowPassword(false);
    };

    /**
     * SUBMIT LOGIN
     */
    const submitLogin = async () => {
        // If it does not have one of the credentials (despite the required prop)
        if (!email || !password) {
            setError('Preencha e-mail e senha para continuar!');
            return;
        }

        // Call the login route
        await api
            .post('/api/v0/users/login/', {
                email,
                password,
            })
            .then(async (res: any) => {
                if (!res) {
                    setError('Erro ao fazer login.');
                    return;
                }

                // Store the access token on the local storage
                setToken(res.data['access_token']);

                // Get user data and save on localstorage
                await fetchUserData();
                await fetchCompanyVisualData();

                // Redirect to the home
                setIsLogged(true);
                window.location.replace(AppRoutes.Home.path);
            })
            .catch(() => {
                setError('Usuário ou senha inválidos.');
            });
    };

    const { mutateAsync, isPending } = useMutation({
        mutationFn: submitLogin,
        onError: () => setError('Erro ao fazer login.'),
    });

    /**
     * RENDER COMPONENT
     */
    return (
        <Box component={'main'}>
            <Stack
                alignItems={'center'}
                justifyContent={'center'}
                sx={{
                    height: '100vh',
                    px: 3,
                    py: 0,
                    my: 0,
                }}
            >
                <Stack
                    sx={{
                        width: '100%',
                        maxWidth: '350px',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center',
                            mb: 3,
                        }}
                        children={<Box component={'img'} src={AppLogo} alt={'Logomarca'} width={'90%'} />}
                    />
                    {error && <Alert severity={'error'} sx={{ mb: 2 }} children={error} />}
                    <Box
                        component={'form'}
                        onSubmit={(event) => {
                            // Preventing to submit form
                            event.preventDefault();
                            mutateAsync();
                        }}
                    >
                        <Stack
                            sx={{
                                width: '100%',
                                gap: '1.5rem',
                            }}
                        >
                            <TextField
                                label={'E-mail'}
                                name={'email'}
                                onChange={(e) => setEmail(e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position={'start'} children={<PersonIcon />} />,
                                }}
                            />
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                label={'Senha'}
                                name={'password'}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position={'start'} children={<KeyIcon />} />,
                                    endAdornment: (
                                        <InputAdornment
                                            position={'end'}
                                            children={
                                                <IconButton
                                                    onMouseUp={handleMouseUpPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    children={showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                />
                                            }
                                        />
                                    ),
                                }}
                            />
                            <Button
                                variant={'contained'}
                                type={'submit'}
                                size={'large'}
                                startIcon={(isPending || isLogged) && <CircularProgress size={20} />}
                                disabled={isPending || isLogged}
                                sx={{ p: 1.5 }}
                                children={'Entrar'}
                            />
                        </Stack>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
};

export default LoginPage;
